import React from "react";

import { Article, DEFAULT_LENGTH, INIT_COMMENT_LENGTH } from "./types";
import ServiceYahooComments from "./ServiceYahooComments";
import ExternalArticleSummary from "./ExternalArticleSummary";
import ArticlesListLoading from "./ArticlesListLoading";
import AlertError from "components/Alerts/AlertError";
import AlertInfo from "components/Alerts/AlertInfo";
import { useSWRFetch } from "libs/api";

type Props = {
  category?: string;
  keyword?: string;
  page?: number;
  length?: number;
  query?: string;
  className?: string;
  comment?: number;
};
export default function ServiceYahooCategory({
  category = "",
  keyword = "",
  page = 0,
  length = DEFAULT_LENGTH,
  query = "",
  className,
  comment = INIT_COMMENT_LENGTH,
}: Props) {
  if (!page || page < 0) page = 0;
  if (!comment || comment < 0) comment = 0;

  const start = page * length;
  const q =
    query && query.length > 2 ? "&search[value]=" + encodeURI(query) : "";
  const url = `${process.env.REACT_APP_API_PATH_YAHOO}?c=${category}&kw=${keyword}&start=${start}&length=${length}${q}`;
  const { data: news, isLoading, error, noData } = useSWRFetch(url);

  if (error)
    return (
      <div className="flex flex-wrap">
        <div className="w-full p-3">
          <AlertError
            message={`Could not load Yahoo ${keyword} ${category} news`}
          />
        </div>
      </div>
    );
  if (isLoading) return <ArticlesListLoading />;

  if (noData)
    return (
      <div className="flex flex-wrap">
        <div className="w-full p-3">
          <AlertInfo
            message={`There is no Yahoo ${keyword} ${category} news`}
          />
        </div>
      </div>
    );

  return (
    <div>
      {news.map((article: Article, i: number) => (
        <div key={i} className={className}>
          <ExternalArticleSummary
            article={article}
            internalHref={`/article/keyword/${article.id}`}
          />
          {comment ? (
            <ServiceYahooComments link={article.link} comment={comment} />
          ) : (
            <></>
          )}
        </div>
      ))}
    </div>
  );
}
