export default {
  metadata: {
    news: {
      categories: [
        {
          id: "technology",
          name: "Công nghệ",
        },
        {
          id: "business",
          name: "Kinh doanh",
        },
        {
          id: "world",
          name: "Thế giới",
        },
        {
          id: "sports",
          name: "Thể thao",
        },
        {
          id: "auto",
          name: "Xe",
        },
      ],
      publishers: [
        //"Football Italia",
        //"Football.London",
        "VnExpress",
        "VTC",
        "VOV",
        "TuoiTre",
        "BongDaSo",
        "TheThao247",
      ],
      keywords: ["Golf", "Ukraine"],
    },
    newspicks: {
      categories: [
        {
          id: "technology",
          name: "テクノロジ",
        },
        {
          id: "business",
          name: "ビジネス",
        },
        {
          id: "economic",
          name: "政治・経済",
        },
        {
          id: "market",
          name: "金融・マーケット",
        },
      ],
      keywords: [
        "ソフトバンクグループ",
        "トヨタ",
        "ゴルフ",
        "ウクライナ",
        "イスラエル",
      ],
    },
    yahoo: {
      categories: [
        {
          id: "world",
          name: "国際",
        },
        {
          id: "technology",
          name: "テクノロジ",
        },
        {
          id: "business",
          name: "経済",
        },
      ],
      keywords: [
        "ソフトバンクグループ",
        //"キャンピングトレーラー",
        //"キャンピングカー",
        "ゴルフ",
        "アルファード",
        "ハイエース",
        "ベトナム",
        "ウクライナ",
      ],
    },
  },
};
